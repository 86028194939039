// ------------------------------------------------------ NAV //

// --------------------------- Dropdown //
$('.nav-container .dropdown').on({
	mouseenter() {
		$(this).children('ul').stop(false, true).addClass('open').fadeIn();
	},
	mouseleave() {
		$(this).children('ul').stop(false, true).fadeOut(() => {
			$(this).removeClass('open');
		});
	},
});

// ------------------------------------------------------ MOBILE NAV //
let navOpen = false;

// --------------------------- Open Nav //
$('body').on('click', '.open-nav', () => {
	$('.slide-out-container').addClass('open');

	setTimeout(() => {
		navOpen = true;
	}, 500);
});

// --------------------------- Close Nav //
$('body').on('click', '.close-nav, .slide-out-container a, #fullpage, #main-menu', () => {
	if (navOpen) {
		$('.slide-out-container').removeClass('open');
		navOpen = false;
	}
});


// --------------------------- Dropdown items scroll to //
$('body').on('click', '.slide-out-container .dropdown li a', function(e) {
	const anchor = $(this).attr('data-m-menu-anchor');

	if (navOpen) {
		$('.slide-out-container').removeClass('open');
		navOpen = false;
	}

	if (anchor !== 'home' && anchor !== 'media-solutions' && !document.getElementById('article-page')) {
		e.preventDefault();

		$('html, body').animate(
			{
				scrollTop: $(`#${anchor}-slide`).offset().top,
			},
			1000
		);
	}
});
