// ------------------------------------------------------ MOUSE //
$('body').on('mouseenter', '.mouse', function() {
	$(this).removeClass('active');
});

// ------------------------------------------------------ HEADER VISIBILITY & STYLES //
const headerVisibility = destination => {
	if (destination.anchor !== 'home') {
		$('header').fadeOut('slow');
	}
	else {
		$('header').fadeIn('slow');
	}
};

const headerStyle = (destination, direction = false) => {
	if (
		(destination.anchor === 'home' && direction === 'up') ||
		destination.anchor === 'brand-marketing' ||
		destination.anchor === 'performance-marketing' ||
		destination.anchor === 'full-funnel'
	) {
		// Dark //
		$('header').addClass('dark').removeClass('light');
		$('.logo-light').css('opacity', 0);
		$('.logo-dark').css('opacity', 1);
		$('#fp-nav, .fp-slidesNav').addClass('dark');

		document.getElementById('home-video').pause();
	}
	else if (destination.anchor === 'home') {
		// Light //
		$('header').addClass('light').removeClass('dark');
		$('.logo-light').css('opacity', 1);
		$('.logo-dark').css('opacity', 0);
		$('#fp-nav, .fp-slidesNav').removeClass('dark');

		if (!mobile) {
			document.getElementById('home-video').play();
		}
	}
};

// ------------------------------------------------------ NAV DOTS STYLE //
const navDotsStyle = (destination, direction = false) => {
	if (destination.anchor === 'about-us' || destination.anchor === 'contact-us') {
		// Light //
		$('#fp-nav').removeClass('dark');
	}
	else if (
		(destination.anchor === 'home' && direction === 'up') ||
		destination.anchor === 'brand-marketing' ||
		destination.anchor === 'performance-marketing' ||
		destination.anchor === 'full-funnel' ||
		destination.anchor !== 'home'
	) {
		// Dark //
		$('#fp-nav, .fp-slidesNav').addClass('dark');
	}
};

// ------------------------------------------------------ ANIMATIONS //

// --------------------------- Build Animation Objects //
const buildAnimationObjects = (
	prop,
	elms,
	aspectRatio = 'xMaxYMin meet',
	loop = false,
	autoplay = false,
	speed = 1
) => {
	const buildObj = {};
	let prefix = prop.split('-');
	prefix = prefix.map(pfx => pfx[0]).join('');


	elms.forEach((section, i) => {
		buildObj[`${prefix}-${section}`] = lottie.loadAnimation({
			container: document.getElementById(`${prefix}-${section}`),
			renderer: 'svg',
			loop,
			autoplay,
			path: `/assets/json/${prop}/${section}.json`,
			rendererSettings: {
				preserveAspectRatio: aspectRatio,
			},
		});

		// Set animation speed //
		buildObj[`${prefix}-${section}`].setSpeed(speed);

		// Play for one cycle //
		buildObj[`${prefix}-${section}`].addEventListener('complete', () => {
			buildObj[`${prefix}-${section}`].stop();
		});
	});

	return buildObj;
};

// --------------------------- Destroy Animation Objects //
const destroyAnimationsObjects = () => {
	if (marketingObj) {
		Object.values(marketingObj).forEach((elm, i) => {
			elm.destroy();
		});
	}

	if (headerIconObj) {
		Object.values(headerIconObj).forEach((elm, i) => {
			elm.destroy();
		});
	}

	if (mediaSolutionsObj) {
		Object.values(mediaSolutionsObj).forEach((elm, i) => {
			elm.destroy();
		});
	}
};

// --------------------------- Animation Actions //
const animationAction = {
	play(id, type) {
		if (type === 'marketing') {
			marketingObj[id].play();
		}
		else if (type === 'icons') {
			headerIconObj[id].play();
		}
		else if (type === 'standOut') {
			standOutObj[id].play();
		}
		else if (type === 'mediaSolutions') {
			mediaSolutionsObj[id].play();
		}
	},
	stop(id, type) {
		if (type === 'marketing') {
			marketingObj[id].stop();
		}
		else if (type === 'icons') {
			headerIconObj[id].stop();
		}
		else if (type === 'standOut') {
			standOutObj[id].stop();
		}
		else if (type === 'mediaSolutions') {
			mediaSolutionsObj[id].stop();
		}
	},
	destroy(id, type) {
		if (type === 'marketing') {
			marketingObj[id].destroy();
		}
		else if (type === 'icons') {
			headerIconObj[id].destroy();
		}
		else if (type === 'standOut') {
			standOutObj[id].destroy();
		}
		else if (type === 'mediaSolutions') {
			mediaSolutionsObj[id].destroy();
		}
	},
};

// --------------------------- Animation Mouse Enter //
$('body').on(
	{
		mouseenter() {
			animationAction.play(this.dataset.id, this.dataset.type);
		},
	},
	'.anim'
);

// --------------------------- Box Animation End Event //
const boxElements = document.getElementsByClassName('box');

Object.values(boxElements).forEach((box, index) => {
	box.addEventListener('animationend', elm => {
		$(elm.target).addClass('on').removeClass('active');
	});
});

// --------------------------- Marketing Animations //
const marketingElms = ['marketing-brand', 'marketing-performance', 'full-funnel'];
let marketingObj;

// Builds the Marketing Animations //
const buildMarketingAnimations = () => {
	marketingObj = buildAnimationObjects('marketing', marketingElms, 'xMaxYMin meet', true, false);
};

buildMarketingAnimations();

// Trigger Marketing Animations //
const marketingAnimations = destination => {
	if (destination.anchor === 'brand-marketing') {
		animationAction.play('m-marketing-brand', 'marketing');
	}
	else if (destination.anchor === 'performance-marketing') {
		animationAction.play('m-marketing-performance', 'marketing');
	}
	else if (destination.anchor === 'full-funnel') {
		animationAction.play('m-full-funnel', 'marketing');
	}
};

// --------------------------- Header Icon Animations //
const iconElms = [
	'splash',
	'marketing-brand',
	'marketing-performance',
	'full-funnel',
	'star',
	'compass',
	'map',
	'location',
];
let headerIconObj;

// Builds the Header Icon Animations //
const buildHeaderIconAnimations = () => {
	headerIconObj = buildAnimationObjects('header-icons', iconElms, 'xMinYMin meet', false, false);
};

buildHeaderIconAnimations();

// Trigger Header Icon Animations //
const headerIconAnimations = destination => {
	if (destination.anchor === 'home') {
		animationAction.play('hi-splash', 'icons');
	}
	else if (destination.anchor === 'brand-marketing') {
		animationAction.play('hi-marketing-brand', 'icons');
		animationAction.play('m-marketing-brand', 'marketing');
	}
	else if (destination.anchor === 'performance-marketing') {
		animationAction.play('hi-marketing-performance', 'icons');
		animationAction.play('m-marketing-performance', 'marketing');
	}
	else if (destination.anchor === 'full-funnel') {
		animationAction.play('hi-full-funnel', 'icons');
		animationAction.play('m-full-funnel', 'marketing');
	}
	else if (destination.anchor === 'facts-and-benefits') {
		animationAction.play('hi-star', 'icons');
	}
	else if (destination.anchor === 'resources') {
		animationAction.play('hi-compass', 'icons');
	}
	else if (destination.anchor === 'about-us') {
		animationAction.play('hi-map', 'icons');
		animationAction.play('hi-location', 'icons');
	}
};

// --------------------------- Stand Out Animations //
const standOutElms = [
	'audience',
	'leadership',
	'full-funnel',
	'attribution',
	'segmentation',
	'marketing',
	'insights',
	'creative',
];
const standOutObj = buildAnimationObjects('stand-out', standOutElms, 'xMaxYMin meet', false, false, 2);

// --------------------------- Media Solutions Animations //
const mediaSolutionsElms = ['ott-ctv', 'video', 'display', 'high-impact', 'cross-device', 'audio', 'native', 'dooh'];
let mediaSolutionsObj;

// Builds the Media Solutions Animations //
const buildMediaSolutionsAnimations = () => {
	mediaSolutionsObj = buildAnimationObjects('media-solutions', mediaSolutionsElms, 'xMaxYMin meet', false, false, 2);
};

buildMediaSolutionsAnimations();

// Trigger Media Solutions Animations //
const mediaSolutionsAnimations = destination => {
	if (destination.anchor === 'media-solutions') {
		animationAction.play('ms-ott-ctv', 'mediaSolutions');
		animationAction.play('ms-video', 'mediaSolutions');

		animationAction.stop('ms-high-impact', 'mediaSolutions');
		animationAction.stop('ms-display', 'mediaSolutions');
		animationAction.stop('ms-cross-device', 'mediaSolutions');
		animationAction.stop('ms-audio', 'mediaSolutions');
		animationAction.stop('ms-native', 'mediaSolutions');
		animationAction.stop('ms-dooh', 'mediaSolutions');
	}
	else if (destination.anchor === 'media-solutions-2') {
		animationAction.play('ms-high-impact', 'mediaSolutions');
		animationAction.play('ms-display', 'mediaSolutions');
		animationAction.play('ms-cross-device', 'mediaSolutions');

		animationAction.stop('ms-ott-ctv', 'mediaSolutions');
		animationAction.stop('ms-video', 'mediaSolutions');
		animationAction.stop('ms-audio', 'mediaSolutions');
		animationAction.stop('ms-native', 'mediaSolutions');
		animationAction.stop('ms-dooh', 'mediaSolutions');
	}
	else if (destination.anchor === 'media-solutions-3') {
		animationAction.play('ms-audio', 'mediaSolutions');
		animationAction.play('ms-native', 'mediaSolutions');
		animationAction.play('ms-dooh', 'mediaSolutions');

		animationAction.stop('ms-ott-ctv', 'mediaSolutions');
		animationAction.stop('ms-video', 'mediaSolutions');
		animationAction.stop('ms-high-impact', 'mediaSolutions');
		animationAction.stop('ms-display', 'mediaSolutions');
		animationAction.stop('ms-cross-device', 'mediaSolutions');
	}
};

// ------------------------------------------------------ LOCATIONS SCROLL //
const locationsTop = document.getElementById('locations-top');
let locationContainerStart = false;
let locationContainerStartCount = 0;
let locationContainerEnd = true;
let locationContainerEndCount = 0;

const resetLocationContainer = () => {
	locationContainerStart = false;
	locationContainerStartCount = 0;
	locationContainerEnd = false;
	locationContainerEndCount = 0;
};

// Mouse scroll detection //
$('.locations-container').on('mousewheel DOMMouseScroll', function(e) {
	if (!mobile) {
		// Hide Mouse on Scroll //
		setTimeout(() => {
			$('.mouse.about-us').fadeOut(1000);
		}, 1000);

		// Scroll down //
		if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
			locationContainerEnd = true;
			locationContainerEndCount += 1;
		}
		else if ($(this).scrollTop() === 0) {
			// Scroll Up //
			locationContainerStart = true;
			locationContainerStartCount += 1;
		}
		else {
			resetLocationContainer();
		}

		// Scroll down //
		if (locationContainerEnd && locationContainerEndCount > 1) {
			if (e.originalEvent.wheelDelta / 120 > 0) {
				locationContainerEndCount = 0;
				locationContainerEnd = false;
			}
			else {
				locationContainerEndCount += 1;
			}
		}

		// Scroll down //
		if (locationContainerEnd && locationContainerEndCount > 18) {
			myFullpage.moveSectionDown();
		}
		else if (locationContainerStart && locationContainerStartCount > 5) {
			// Scroll Up //
			myFullpage.moveTo('media-solutions', 'media-solutions-3');
		}
	}
});

// ------------------------------------------------------ FULLPAGE JS //
let mobile = false;

const myFullpage = new fullpage('#fullpage', {
	// Keys //
	licenseKey: 'CCB254D0-02964DE5-A4FDEA1E-D221B595',
	scrollHorizontallyKey: 'QU5ZXzU0V2MyTnliMnhzU0c5eWFYcHZiblJoYkd4NVc3Nw==',
	responsiveSlidesKey: 'QU5ZX2ZXaGNtVnpjRzl1YzJsMlpWTnNhV1JsY3c9PXZBQw==',

	// Custom selectors //
	sectionSelector: '.section',
	slideSelector: '.slide',

	// Navigation //
	menu: '#main-menu',
	lockAnchors: false,
	anchors: ['home', 'facts-and-benefits', 'resources', 'media-solutions', 'about-us', 'contact-us'],
	navigation: true,
	navigationPosition: 'right',

	// Scrolling //
	scrollingSpeed: 1000,

	// Vertical //
	verticalCentered: false,

	// Horizontal //
	loopHorizontal: false,
	continuousHorizontal: true,
	scrollHorizontally: true,
	normalScrollElements: '.locations-container',
	touchSensitivity: 15,
	bigSectionsDestination: 'top',

	// Design //
	controlArrows: false,
	fixedElements: 'header',
	responsiveWidth: 1025,
	responsiveHeight: 0,
	responsiveSlides: true,

	// Events //
	onLeave(origin, destination, direction) {
		// --------------------------- Header Visibility //
		headerVisibility(destination);
		headerStyle(destination, direction);

		// --------------------------- Nav dots style  //
		navDotsStyle(destination, direction);

		// --------------------------- Reset Locations Scroll position //
		if (origin.anchor === 'about-us') {
			resetLocationContainer();
		}
	},
	afterLoad(origin, destination, direction) {
		// --------------------------- Mouse Animation //
		$(`.mouse.${destination.anchor}`).addClass('active');

		// --------------------------- Header Visibility & Styles //
		headerVisibility(destination);
		headerStyle(destination, direction);

		// --------------------------- Nav dots style  //
		navDotsStyle(destination);

		// --------------------------- Icon Animations //
		headerIconAnimations(destination);

		// --------------------------- Marketing Animations //
		marketingAnimations(destination);

		// --------------------------- Box Animation //
		if (!$(`[data-anchor="${destination.anchor}"] .box`).hasClass('on')) {
			$(`[data-anchor="${destination.anchor}"] .box`).addClass('active');
		}

		// --------------------------- Reset Locations Scroll position //
		if (origin.anchor === 'about-us') {
			locationsTop.scrollTo(0, 0, 0);
			resetLocationContainer();
		}
	},
	afterRender() {
		destroyAnimationsObjects();
		buildHeaderIconAnimations();
		buildMarketingAnimations();
		buildMediaSolutionsAnimations();
	},
	afterResponsive(isResponsive) {
		mobile = isResponsive;

		if (mobile) {
			destroyAnimationsObjects();
			buildHeaderIconAnimations();
			buildMarketingAnimations();
			buildMediaSolutionsAnimations();

			document.getElementById('home-video').pause();
		}
		else {
			document.getElementById('home-video').play();
		}
	},
	afterSlideLoad(section, origin, destination) {
		// --------------------------- Mouse Animation //
		$(`.mouse.${destination.anchor}`).addClass('active');

		// --------------------------- Header Styles //
		headerStyle(destination);

		// --------------------------- Nav dots style  //
		navDotsStyle(destination);

		// --------------------------- Icon Animations //
		headerIconAnimations(destination);

		// --------------------------- Marketing Animations //
		marketingAnimations(destination);

		// --------------------------- Media Solutions Animations //
		mediaSolutionsAnimations(destination);
	},
	onSlideLeave(section, origin) {
		// --------------------------- Reset Locations Scroll position //
		if (origin.anchor === 'about-us') {
			resetLocationContainer();
		}
	},
});

// ------------------------------------------------------ CONTACT FORM //
_validator = $('#contact-form').validate({
	ignore: [],
	onfocusout(element) {
		$(element).valid();
	},
	rules: {
		name: {
			required: true,
		},
		email: {
			required: true,
			email: true,
		},
		company: {
			required: true,
		},
		phone: {
			required: true,
			phoneUS: true,
		},
	},
	messages: {
		name: {
			required: 'Name is required.',
		},
		email: {
			required: 'Email is required.',
		},
		company: {
			required: 'Company is required.',
		},
		phone: {
			required: 'Phone is required.',
		},
	},
	highlight(element) {
		$(element).closest('div').addClass('error').removeClass('valid');
	},
	unhighlight(element) {
		$(element).closest('div').removeClass('error').addClass('valid');
		$(element).next('label').remove();
	},
	errorPlacement(error, element) {
		const message = error.html();

		if (message.length > 0) {
			$(element[0]).closest('div.error').append(error);
		}
	},
	// ---------------------------------------------------- FORM SUBMIT HANDLER //
	submitHandler() {
		$('#submit-button').prop('disabled', true).text('Sending');

		$.post('/ajax/process.php', $('#contact-form').serialize(), response => {
			const data = JSON.parse(response);

			if (data.valid) {
				// Success //
				document.getElementById('contact-form').reset();
				$('#contact-form .message').html(data.msg).addClass('active');
			}
			else {
				// Error //
				$('#contact-form .message').html(data.msg).addClass('error active');
			}

			setTimeout(() => {
				$('#contact-form .message').removeClass('active error');
			}, 5000);

			$('#submit-button').prop('disabled', false).text('Submit');
		});
	},
});

$('input[name="phone"]').mask('000-000-0000');

const contactPlane = lottie.loadAnimation({
	container: document.getElementById('contact-plane'),
	renderer: 'svg',
	loop: true,
	autoplay: true,
	path: '/assets/json/contact-plane.json',
	rendererSettings: {
		preserveAspectRatio: 'xMinYMin meet',
	},
});
