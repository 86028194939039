// ------------------------------------------------------ COOKIES / GDPR //

// --------------------------- Check if user has agreed to cookies //
function readCookies() {
	const allCookies = document.cookie;
	let cookieFound = false;
	let name;
	let value;

	// Get all the cookies pairs in an array
	const cookieArray = allCookies.split(';');

	// Now take key value pair out of this array
	for (let i = 0; i < cookieArray.length; i += 1) {
		name = cookieArray[i].split('=')[0].replace(' ', '');
		value = cookieArray[i].split('=')[1];

		if (name === 'cookie-agreed' && value === '1') {
			cookieFound = true;
			break;
		}
	}

	return cookieFound;
}

if (!readCookies()) {
	$('.gdpr').css('display', 'grid');
}

// --------------------------- Agree to cookies //
$('body').on('click', '.gdpr-submit', () => {
	const CookieDate = new Date();
	CookieDate.setFullYear(CookieDate.getFullYear() + 1);
	document.cookie = `cookie-agreed=1;expires=${CookieDate.toGMTString()};path=/`;

	$('.gdpr').fadeOut();
});
